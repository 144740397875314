import React, { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Router, Switch, Route, Redirect } from 'react-router-dom'

import './App.css'
import './locales/i18n'

import Login from './components/Pages/Login/Login'

import { clearMessage } from './actions/message'
import { history } from './helpers/history'
import Header from './components/Pages/shared/Header'
import Navigation from './components/Pages/shared/Navigation'
import { checkAuth } from './helpers/checkAuth'
import CssBaseline from '@mui/material/CssBaseline'
import { styled } from '@mui/system'
import Challenges from './components/Pages/Challenges/Challenges'
import Home from './components/Pages/Home/Home'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import UserPlanImport from './components/Pages/UserPlanImport/UserPlanImport'
import UserPlans from './components/Pages/UserPlans/UserPlans'
import StorePlans from './components/Pages/StorePlans/StorePlans'
import Stores from './components/Pages/Stores/Stores'

const RootDiv = styled('div')({
  display: 'flex',
})

const MainContent = styled('main')({
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
})

const App = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState<boolean>(false)
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    history.listen(() => {
      dispatch(clearMessage())
    })
  }, [dispatch])

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])
  return (
    <RootDiv>
      <CssBaseline />
      {currentUser && (
        <>
          <Header
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            messengerToken={currentUser.messengerToken}
            currentUser={currentUser}
          />
          <Navigation
            open={open}
            handleDrawerClose={handleDrawerClose}
            currentUser={currentUser}
          />
        </>
      )}
      <MainContent>
        <Router history={history}>
          <div style={{ height: '100px' }} />
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path={['/', '/home']} component={Home} />
            <Route path={['/challenges']} component={Challenges} />
            <Route
              exact
              path={['/user-plan-import']}
              component={UserPlanImport}
            />
            <Route
              exact
              path={['/user-plans']}
              component={UserPlans}
            />
            <Route
              exact
              path={['/store-plans/:userId/:periodId']}
              component={StorePlans}
            />
            <Route
              exact
              path={['/stores']}
              component={Stores}
            />
            <Redirect to="/login" />
          </Switch>
        </Router>
      {currentUser && (
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          style={{ whiteSpace: 'pre-line' }}
        />
      )}
      </MainContent>
    </RootDiv>
  )
}

export default App
