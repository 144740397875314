import React, { FunctionComponent, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { login } from '../../../actions/auth'
import Copyright from '../shared/Copyright'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Alert,
  TextField,
  CircularProgress,
  Grid,
  Box,
  Link,
  InputAdornment,
} from '@mui/material'
import PrimaryButton from '../../../styles/Buttons/PrimaryButton'
import { styled } from '@mui/material/styles'
import bgImage from '../../../assets/images/background/login-bg.png'
import Logo from '../../../assets/images/logos/logo.png'
import ActionLogo from '../../../assets/images/logos/action-logo.png'
import PersonIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/Lock'
import VpnKeyIcon from '@mui/icons-material/VpnKey'

type LoginProps = {
  history: string[]
}
const LoginContainer = styled(Container)(({ theme }) => ({
  position: 'relative',
  marginTop: '-100px',
  height: '100%',
  width: '100% !important',
  maxWidth: 'unset !important',
  backgroundImage: `url(${bgImage})`,
  backgroundPosition: 'center',
  backgroundPositionY: -60,
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  padding: 0 + '!important',
  '.logo-container': {
    width: 160,
    height: 80,
    marginBottom: 20,
    '.logo': {
      width: '100%',
    },
  },
  '.login-box': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'min-content',
    left: '100px',
    backgroundColor: '#FFF',
    boxShadow: theme.shadows[3],
    borderRadius: '25px',
    maxWidth: '350px !important',
    padding: '40px',
    '.login-icons': {
      fill: theme.colorsPalette.actionColors.primary.main,
    },
    '.login-button-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '20px 0px',
    },
    '.forgot-password-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        marginLeft: 10,
        color: theme.colorsPalette.actionColors.primary.main,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  '.footer-container': {
    width: '100%',
    height: '60px',
    backgroundColor: '#FFF',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '.action-logo-container': {
      position: 'absolute',
      bottom: 30,
      left: 180,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 170,
      height: 80,
      '.logo': {
        width: '100%',
        height: '100%',
      },
    },
    '.footer-copyright-container': {
      width: '100%',
      paddingLeft: 500,
    },
  },
}))

const Login: FunctionComponent<LoginProps> = ({ history }) => {
  const { t } = useTranslation()

  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [usernameError, setUsernameError] = useState<boolean>(false)
  const [passwordError, setPasswordError] = useState<boolean>(false)

  const { isLoggedIn } = useSelector((state: RootStateOrAny) => state.auth)
  const { message } = useSelector((state: RootStateOrAny) => state.message)

  const dispatch = useDispatch()

  const onChangeUsername = (e: { target: { value: string } }) => {
    const username = e.target.value
    setUsername(username)
    setUsernameError(false)
  }

  const onChangePassword = (e: { target: { value: string } }) => {
    const password = e.target.value
    setPassword(password)
    setPasswordError(false)
  }

  const handleLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault()

    if (username.length === 0) {
      setUsernameError(true)
      return
    } else if (password.length === 0) {
      setPasswordError(true)
      return
    }

    setLoading(true)

    try {
      await dispatch(login(username, password))
      history.push('/home')
      // window.location.reload()
    } catch (error) {
      setLoading(false)
    }
  }

  if (isLoggedIn) {
    return <Redirect to="/home" />
  }

  return (
    <LoginContainer>
      <div className="login-box">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid className="logo-container">
            <img className="logo" src={Logo} alt="" />
          </Grid>
          <Box
            component="form"
            onSubmit={handleLogin}
            noValidate
            sx={{ mt: 1 }}
          >
            {message && (
              <Alert severity="error" style={{ marginBottom: '1.5rem' }}>
                {t('pages.login.unathorized')}
              </Alert>
            )}
            <TextField
              error={usernameError}
              helperText={usernameError ? t('pages.login.usernameError') : ''}
              variant="outlined"
              margin="none"
              value={username}
              fullWidth
              id="email"
              label={t('pages.login.email')}
              name="email"
              type="email"
              onChange={onChangeUsername}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon className="login-icons" />
                  </InputAdornment>
                ),
              }}
              autoFocus
            />
            <TextField
              error={passwordError}
              helperText={passwordError ? t('pages.login.passwordError') : ''}
              variant="outlined"
              margin="normal"
              value={password}
              fullWidth
              name="password"
              label={t('pages.login.password')}
              type="password"
              onChange={onChangePassword}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon className="login-icons" />
                  </InputAdornment>
                ),
              }}
            />
            <Grid className="login-button-container" container>
              <PrimaryButton
                className="login-button"
                type="submit"
                disabled={loading}
                onClick={handleLogin}
              >
                {t('pages.login.submit')}
                {loading && (
                  <CircularProgress
                    size={18}
                    color="secondary"
                    style={{ marginLeft: '1rem' }}
                  />
                )}
              </PrimaryButton>
            </Grid>
            <Grid container>
              <Grid className="forgot-password-container" item xs>
                <VpnKeyIcon className="login-icons" />
                <Link href="#" variant="body2">
                  {t('pages.login.forgotPassword')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
      <Box className="footer-container" mt={8}>
        <div className="action-logo-container">
          <img className="logo" src={ActionLogo} alt="" />
        </div>
        {/*<div className="footer-txt">
          <div className="footer-txt_top">
            Operatorem systemu jest <span className="p4t-text-color">P4T Play for trade</span> <a href="https://p4t.com.pl" target="_blank" rel="noopener noreferrer">www.p4t.com.pl</a>
          </div>
          <div className="footer-txt_bottom">
            <a href="https://rodo.p4t.com.pl/Polityka_prywatnosci_p4t.pdf" target="_blank" rel="noopener noreferrer">Zasady korzystania z serwisu i przetwarzania danych.</a>
          </div>
        </div> */}
        <div className="footer-copyright-container">
          <Copyright />
        </div>
      </Box>
    </LoginContainer>
  )
}

export default Login
