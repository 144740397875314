import { history } from './history'

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bajtów', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function getBase64(file: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export function thousandsSeparator(num: number): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

export function odmiana(
  liczba: number,
  pojedyncza: string,
  mnoga: string,
  mnoga_dopelniacz: string,
): string {
  liczba = Math.abs(liczba) // tylko jeśli mogą zdarzyć się liczby ujemne
  if (liczba === 1) return pojedyncza
  var reszta10 = liczba % 10
  var reszta100 = liczba % 100
  if (reszta10 > 4 || reszta10 < 2 || (reszta100 < 15 && reszta100 > 11))
    return mnoga_dopelniacz
  return mnoga
}

export const handleNavigationClick = (page: string) => {
  if (page.charAt(0) === '/') {
    page = page.substring(1)
  }
  history.push(`/${page}`)
}

// Below are the functions that handle actual exporting:
// getSVGString ( svgNode ) and svgString2Image( svgString, width, height, format, callback )
export function getSVGString(svgNode: Node | null) {
  // @ts-ignore
  svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink')
  // @ts-ignore
  var cssStyleText = getCSSStyles(svgNode)
  // @ts-ignore
  appendCSS(cssStyleText, svgNode)

  var serializer = new XMLSerializer()
  // @ts-ignore
  var svgString = serializer.serializeToString(svgNode)
  svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink=') // Fix root xlink without namespace
  svgString = svgString.replace(/NS\d+:href/g, 'xlink:href') // Safari NS namespace fix

  return svgString

  function getCSSStyles(parentElement: {
    id: string
    classList: string | any[]
    getElementsByTagName: (arg0: string) => any
  }) {
    var selectorTextArr = []

    // Add Parent element Id and Classes to the list
    selectorTextArr.push('#' + parentElement.id)
    for (var c = 0; c < parentElement.classList.length; c++)
      if (!contains('.' + parentElement.classList[c], selectorTextArr))
        selectorTextArr.push('.' + parentElement.classList[c])

    // Add Children element Ids and Classes to the list
    var nodes = parentElement.getElementsByTagName('*')
    for (var i = 0; i < nodes.length; i++) {
      var id = nodes[i].id
      if (!contains('#' + id, selectorTextArr)) selectorTextArr.push('#' + id)

      var classes = nodes[i].classList
      for (var d = 0; d < classes.length; d++)
        if (!contains('.' + classes[d], selectorTextArr))
          selectorTextArr.push('.' + classes[d])
    }

    // Extract CSS Rules
    var extractedCSSText = ''
    for (var j = 0; j < document.styleSheets.length; j++) {
      var s = document.styleSheets[j]

      try {
        if (!s.cssRules) continue
      } catch (e) {
        // @ts-ignore
        if (e.name !== 'SecurityError') throw e // for Firefox
        continue
      }

      var cssRules = s.cssRules
      for (var r = 0; r < cssRules.length; r++) {
        // @ts-ignore
        if (contains(cssRules[r].selectorText, selectorTextArr))
          extractedCSSText += cssRules[r].cssText
      }
    }

    return extractedCSSText

    function contains(str: string, arr: string | any[]) {
      return arr.indexOf(str) === -1 ? false : true
    }
  }

  function appendCSS(
    cssText: string,
    element: {
      hasChildNodes: () => any
      children: any[]
      insertBefore: (arg0: HTMLStyleElement, arg1: any) => void
    },
  ) {
    var styleElement = document.createElement('style')
    styleElement.setAttribute('type', 'text/css')
    styleElement.innerHTML = cssText
    var refNode = element.hasChildNodes() ? element.children[0] : null
    element.insertBefore(styleElement, refNode)
  }
}

export function svgString2Image(
  svgString: string | number | boolean,
  width: number,
  height: number,
  format: string,
  callback: {
    (dataBlob: any, filesize: any): void
    (arg0: Blob | null, arg1: string): void
  },
) {
  // var format = format ? format : 'png'

  var imgsrc =
    'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString))) // Convert SVG string to data URL

  var canvas = document.createElement('canvas')
  var context = canvas.getContext('2d')

  canvas.width = width
  canvas.height = height

  var image = new Image()
  image.onload = function () {
    // @ts-ignore
    context.clearRect(0, 0, width, height)
    // @ts-ignore
    context.drawImage(image, 0, 0, width, height)

    canvas.toBlob(function (blob) {
      // @ts-ignore
      var filesize = Math.round(blob.length / 1024) + ' KB'
      if (callback) callback(blob, filesize)
    })
  }

  image.src = imgsrc
}

export const wrapSvgText = (text: any, width: number, d3: typeof import("d3")) => {
  var that = text
  text.each((t: string, i: number) => {
    var text = d3.select(that._groups[0][i]),
      words = t.split(/\s+/).reverse(),
      word,
      line: string[] = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      y = text.attr('y'),
      dy = parseFloat(text.attr('dy')),
      tspan = text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', dy + 'em')
    while ((word = words.pop())) {
      line.push(word)
      tspan.text(line.join(' '))
      // @ts-ignore
      if (tspan.node().getComputedTextLength() > width) {
        line.pop()
        tspan.text(line.join(' '))
        line = [word]
        tspan = text
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', ++lineNumber * lineHeight + dy + 'em')
          .text(word)
      }
    }
  })
}

export function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}
